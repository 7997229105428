<template>
	<div class="open-success-page">
		<van-row class="content">
			<van-col span="24">
				<img :src="icon" alt="" class="icon-success">
			</van-col>
			<van-col span="24" class="notice-title">{{translate('audit_failure')}}</van-col>
			<van-col span="24" style="margin: 20px 0 0 0;color: #555;">{{remarks}}</van-col>
		</van-row>
		
		<div class="common_block">
			<van-button type="warning" block round @click="nextStep">{{translate('to_modify')}}</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'account_success',
		data() {
			return {
				resourceURL: this.$axios.defaults.resourceURL,
				
				icon: this.$axios.defaults.resourceURL + "images/icon_open_user_fail.png",

				token: "",
				userinfo_data: {},
				remarks: ""

			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token

				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						this.remarks = res.data.remarks
						this.userinfo_data = res.data.userinfo_data
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}
		},
		methods: {
			
			nextStep() {
				this.$axios({
					method: 'post',
					url: 'account_open/recover',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						if (this.userinfo_data.nationality != undefined) {
							this.$router.replace('/account/idcard?token=' + this.token)
						} else {
							this.$router.replace('/account/userinfo?token=' + this.token)
						}
						
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			}
		},
	}
</script>